module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"serena pascual","short_name":"sp","start_url":"/","background_color":"#fffdfb","theme_color":"#358182","display":"standalone","icon":"static/icon.png","icons":[{"src":"/static/favicon_16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/favicon_32x32.png","sizes":"32x32","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"37da5978a8a8a26fcd9100314ddc7f9e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
